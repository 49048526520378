import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Política de Privacidade" description="Política de Privacidade - expanda seus negócios usando a automação de marketing multicanal baseada em dados e vença a concorrência!" />
        <Hero title="Política de Privacidade" button="false" classes="heroes--full has-text-centered" />

        <div className="container container--pad pad-t-2 pad-b-6 mar-t-6 mar-b-6">
          <div className="columns">
            <div className="column" />
            <div className="column is-two-thirds">
              <div className="columns is-centered">
                <div className="post__content content column">
                  <p><strong>A ExpertSender está comprometida em proteger a privacidade de nossos clientes e visitantes do site. A manutenção promove confiança, boa vontade e relacionamentos mais fortes. Para obter mais informações sobre como a ExpertSender aplica marketing de permissão por e-mail a nossos clientes, consulte nossa Política Antispam e não hesite em entrar em contato.</strong></p>
                  <h4>Coleção de informações do site</h4>
                  <p>Salvo indicação em contrário em nosso site, coletamos apenas o nome de domínio e o endereço IP de cada visitante da nossa página da web, os endereços de email daqueles que se comunicam conosco por email, informações agregadas e específicas do usuário sobre quais páginas os clientes acessam ou visitam e informações oferecidas pelo consumidor, como informações de pesquisas. Também podemos rastrear tipos de navegadores para nos ajudar a entender as necessidades de nossos visitantes relacionadas ao design do site. Há uma seção opcional do nosso site na qual os formulários de entrada exigem seu nome, número de telefone, empresa e endereço de e-mail. Podemos usar as informações de identificação pessoal coletadas dessa maneira para: (a) enviar para você informações que acreditamos ser do seu interesse por e-mail ou outros meios; (b) enviar a você comunicações de marketing relacionadas aos nossos negócios ou aos negócios de terceiros cuidadosamente selecionados que acreditamos ser do seu interesse.</p> <p>Nunca vendemos, compartilhamos ou trocamos nenhuma de suas informações pessoais identificáveis a terceiros. </p>
                  <p>No entanto, reservamos o direito de divulgar suas informações de identificação pessoal conforme exigido por lei e quando acreditarmos que a divulgação é necessária para proteger nossos direitos e / ou cumprir um processo judicial , ordem judicial ou processo legal veiculado em nosso site. </p>
                  <p>Você pode cancelar a inscrição em nossas comunicações de marketing clicando no link "cancelar inscrição" localizado no rodapé de nossos e-mails ou enviando um e-mail para contato@expertsender.com.br.</p>
                  <p>O uso deste site (sistema da ExpertSender) requer o processamento de dados pessoais de um cliente nos seguintes casos: nome e sobrenome, número de telefone, e-mail, cargo, empresa). Os dados serão processados ​​por: ExpertSender (ExpertSender Sp. Z o.o.) de uma maneira crucial para concordar com um contrato, bem como com seu cumprimento. Os dados da transação, nesses dados pessoais, podem ser transferidos para o benefício do PayLane Sp. z o.o. localizado em Gdańsk em Arkońska 6 / A3, código postal: 80-387, KRS: 0000227278, nos termos em que será vantajoso no serviço conectado aos pagamentos de pedidos. Um cliente tem o direito de ver o conteúdo de seus dados e quaisquer correções feitas nos mesmos. O compartilhamento de informações é voluntário, no entanto, ao mesmo tempo, é crucial o uso deste site.</p>
                  <h4>Suas listas e bancos de dados e email</h4>
                  <p>Suas listas de email são armazenadas em um servidor seguro. Não venderemos, alugaremos, emprestaremos ou convidaremos acesso externo às suas listas, nem a ExpertSender usará suas listas por qualquer outro motivo que não seja o suporte ao cliente e fins de monitoramento de abuso. Você deve ter certeza de que seus dados são mantidos estritamente confidenciais.</p>
                  <h4> Web beacons</h4>
                  <p>A ExpertSender inclui um 'web beacon' na maioria das mensagens de email em HTML enviadas. Um web beacon é de fato uma pequena imagem no conteúdo da mensagem, que é ativada quando um email em HTML é aberto por meio de uma solicitação de imagem em nossos servidores.</p>
                  <p>Também fornecemos a capacidade de rastrear quando um URL foi clicado em uma mensagem de email enviada pelo nosso sistema. Usamos essa funcionalidade para rastrear quais links foram clicados e em qual quantidade, além de determinar o número agregado de links clicados. Essa funcionalidade de rastreamento de cliques não coleta nenhuma informação pessoal identificável.</p>
                  <p>Isso permite que nossos clientes rastreiem o número agregado de emails abertos. O web beacon não coleta nenhuma informação de identificação pessoal. Os usuários que não desejam receber mensagens de email com web beacons podem optar por receber seus emails em formato de texto (não HTML).</p>
                  <h4>Cookies</h4>
                  <p> Usamos cookies para registrar informações da sessão, como atividades anteriores no site, a fim de fornecer um melhor serviço quando os visitantes retornam ao site ou personalizar o conteúdo da página da Web com base no tipo de navegador dos visitantes.</p> <p>Usamos o Google Analytics e redirecionamos os cookies para permitir que terceiros prestadores de serviços terceirizados para fornecer anúncios em nosso nome na Internet. Esses provedores de serviços podem coletar seu endereço IP e dados não pessoais sobre suas visitas. Isso é completamente anônimo e não inclui seu nome, endereço, endereço de e-mail ou outras informações pessoais. Além disso, esses cookies são usados ​​para coletar informações estatísticas anônimas sobre como o site é navegado. Esses cookies expiram após 10 anos.</p>
                  <p>Usamos o Hotjar para entender melhor as necessidades de nossos usuários e otimizar esse serviço e experiência. O Hotjar usa cookies e outras tecnologias para coletar dados sobre o comportamento de nossos usuários e seus dispositivos. Isso inclui o endereço IP de um dispositivo (processado durante sua sessão e armazenado em um formulário não identificado), tamanho da tela do dispositivo, tipo de dispositivo (identificadores exclusivos de dispositivo), informações do navegador.</p>
                </div>
              </div>
            </div>
            <div className="column" />
          </div>
        </div>
      </Layout>
    )
  }
}

export default SuccessStories
